// モジュールのインポート
import React, { FC } from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import FileUploadTest from "./components/FileUploadTest"
import Home from "./components/Home"

// ルーティングの実装
const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="fileuploadtest" element={<FileUploadTest/>}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App