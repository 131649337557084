import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Heading,
  Flex,
  Button,
} from "@chakra-ui/react";

const Home = () => {
  return (
    <Flex justify="center" align="center" h="100vh">
      <Card align="center">
        <CardHeader>
          <Heading><Link to='/fileuploadtest'>Upload</Link></Heading>
        </CardHeader>
      </Card>
    </Flex>
  );
};

export default Home;
